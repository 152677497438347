import React, { useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

import RenderStaticHTML from '../components/RenderStaticHTML';
import PageMask from '../components/PageMask';
import RevealOnLoad from '../components/RevealOnLoad';

const ns = `page-job-detail`;

const JobDetail = ({ data, pageContext }) => {
	const applicationRef = useRef(null);

	const {
		job: {
			title,
			location,
			content,
		},
	} = data;

	const {
		gh_Id: ghId, // set in /gatsby/createJobs.js
	} = pageContext;

	useLayoutEffect(() => {
		const Greenhouse = window.Grnhse;

		if (!Greenhouse || !ghId) return;

		// Loads the appliction form iframe given the ghId
		// Greenhouse 3rd party script is loaded via gatsby-ssr.js
		Greenhouse.Iframe.load(ghId);

		setTimeout(() => {
			const applicationEl = applicationRef.current;
			const iframe = applicationRef.current?.querySelector('iframe');

			if (iframe) {
				const iframeHeight = iframe.offsetHeight;
				applicationEl.style.minHeight = `${ iframeHeight }px`;
			}
		}, 1000);
	});

	const handleScrollTo = () => {
		const applicationEl = applicationRef.current;
		const appElDistanceToTop = window.pageYOffset + applicationEl.getBoundingClientRect().top;

		window.scrollTo(0, appElDistanceToTop);
	};

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<PageMask>
				<Helmet>
					<body data-header-theme={`${ 'dark' }`} />
				</Helmet>
				<div className={`container container--offset`}>
					<RevealOnLoad>
						<div className={`${ ns }__heading`}>
							{title && (
								<h1 className={`${ ns }__title h1-med`}>{title}</h1>
							)}
							<div className={`${ ns }__subtitle h3-reg`}>
								{location?.name && (
									<span>
										{location.name}
										&nbsp;–&nbsp;
									</span>
								)}
								<span>
									<button onClick={handleScrollTo}>Apply Now</button>
								</span>
							</div>
						</div>
						<div className={`${ ns }__body`}>
							<RenderStaticHTML className={`${ ns }__body`} html={parse(content)} />
							<div className={`${ ns }__application`}>
								<div ref={applicationRef} id={`grnhse_app`} />
							</div>
						</div>
					</RevealOnLoad>
				</div>
			</PageMask>
		</div>
	);
};

export default JobDetail;

export const jobDetailQuery = graphql`
	# these variables are passed in via createPage.pageContext in gatsby-node.js
	query GET_JOB(
		$gh_Id: Int
	) {
		# selecting the current job by gh_Id (Greenhouse Job ID)
		job: greenhouseJob(gh_Id: { eq: $gh_Id }) {
			title
			location {
				name
			}
			content
		}
	}
`;
